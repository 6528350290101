import styles from './index.module.css'
import Page from '../components/page'
import { Empty } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import HeaderTab from '../components/header-tab'
import { claimsRoles } from '../utils/constants'
import ScanAdherenceAnalytics from '../components/analytics/scan-adherence'

const tabs = [
	{
		title: 'Scan Adherence Analytics',
		value: 'scan_adherence_analytics'
	}
]

const HomePage = () => {
	const router = useRouter()
	const { role, companyDetails, permissions } = useSelector(state => state.authReducer)
	const [currentTab, setCurrentTab] = useState(null)

	const [mainTabs, setMainTabs] = useState([])

	useEffect(() => {
		const filteredTabs = tabs.filter(tab => {
			if (tab.value === 'scan_adherence_analytics' && !permissions.viewScanAdherenceAnalytics) {
				return false
			}
			return true
		})
		setMainTabs(filteredTabs)
		if (filteredTabs.length) {
			setCurrentTab(filteredTabs[0].value)
		}
	}, [permissions, companyDetails])


	useEffect(() => {
		if (role === claimsRoles.ADMIN) {
			router.push('/companies')
		}
	}, [role])


	const renderDashboardData = () => {
		if (currentTab === 'scan_adherence_analytics') {
			return <ScanAdherenceAnalytics />
		} else {
			return null
		}
	}

	return (
		<Page>
			{
				role !== claimsRoles.ADMIN ?
					<div className={styles.container}>
						<div className={styles.contentHeader}>
							<div className={styles.contentHeaderContainer}>
								<div className={styles.tabContainer}>
									{
										mainTabs.map(tab => {
											return (
												<HeaderTab
													key={tab.value}
													onClick={() => {
														setCurrentTab(tab.value)
													}}
													isSelected={() => currentTab === tab.value}
												>{tab.title}
												</HeaderTab>
											)
										})
									}
								</div>
							</div>
						</div>
						{
							mainTabs.length === 0 ?
								<div className={styles.empty}>
									<Empty description='No Analytics' />
								</div>
								:
								renderDashboardData()
						}
					</div> : null
			}
		</Page>
	)
}

export default HomePage
