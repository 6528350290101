import { Button, Card, Checkbox, Col, DatePicker, Divider, notification, Row, Select, Space } from 'antd'
import React from 'react'
import { useState, useEffect } from 'react'
import { ReloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './scan-adherence.module.css'
import { fetchScanAdherenceNationwideScanMissed, fetchScanAdherenceSummary, fetchScanAdherenceWarehouseWiseScanMissed } from '../../../store/dashboard/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Locations } from '../../../services/api/locations'
import { intervalTypes, locationTypes } from '../../../utils/constants'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import moment from 'moment'

const intervals = [
	{
		label: 'Today',
		value: intervalTypes.DAILY
	},
	{
		label: 'Last 7 Days',
		value: intervalTypes.WEEKLY
	},
	{
		label: 'Last 30 Days',
		value: intervalTypes.MONTHLY
	},
	{
		label: 'Custom',
		value: intervalTypes.CUSTOM
	}
]

const nationwideIntervals = [
	{
		label: 'Daily',
		value: intervalTypes.DAILY
	},
	{
		label: 'Weekly',
		value: intervalTypes.WEEKLY
	},
	{
		label: 'Monthly',
		value: intervalTypes.MONTHLY
	}
]

const getPercentage = (value = 0, total = 0) => {
	const percentage = (value / total) * 100
	return percentage ? percentage : 0
}

const showInPercentage = (value) => {
	return value ? `${value.toFixed(2).replace(/\.0+$/, '')}%` : `${0}%`
}

const ScanAdherenceAnalytics = () => {
	const {
		scanAdherenceSummary,
		warehouseWiseScanMissed,
		totalScanMissedInAllWarehouses,
		nationwideScanMissed
	} = useSelector(state => state.dashboardReducer)
	const { permissions } = useSelector(state => state.authReducer)
	const dispatch = useDispatch()
	const [summaryInterval, setSummaryInterval] = useState(intervals[0].value)
	const [warehouseWiseScanMissedInterval, setWarehouseWiseScanMissedInterval] = useState(intervals[0].value)
	const [nationwideScanMissedInterval, setNationwideScanMissedInterval] = useState(intervals[0].value)
	const [warehouses, setWarehouses] = useState([])
	const [selectedWarehouses, setSelectedWarehouses] = useState([])
	const [isFetchingSummary, setIsFetchingSummary] = useState(false)
	const [isFetchingWarehouses, setIsFetchingWarehouses] = useState(false)
	const [isFetchingWarehouseWiseScanMissed, setIsFetchingWarehouseWiseScanMissed] = useState(false)
	const [isFetchingNationwideScanMissed, setIsFetchingNationwideScanMissed] = useState(false)
	const [warehouseSearchValue, setWarehouseSearchValue] = useState('')
	const [summaryFromDate, setSummaryFromDate] = useState(null)
	const [summaryToDate, setSummaryToDate] = useState(null)
	const [warehouseWiseFromDate, setWarehouseWiseFromDate] = useState(null)
	const [warehouseWiseToDate, setWarehouseWiseToDate] = useState(null)
	const [isAllWarehousesChecked, setIsAllWarehousesChecked] = useState(true)
	const [page, setPage] = useState(1)

	useEffect(() => {
		if (!permissions.viewScanAdherenceAnalytics) return
		getWarehouses()
	}, [])

	useEffect(() => {
		if (!permissions.viewScanAdherenceAnalytics) return
		getScanAdherenceSummary()
	}, [summaryInterval, selectedWarehouses, summaryFromDate, summaryToDate])

	useEffect(() => {
		if (!permissions.viewScanAdherenceAnalytics) return
		getScanAdherenceWarehouseWiseScanMissed()
	}, [warehouseWiseScanMissedInterval, warehouseWiseFromDate, warehouseWiseToDate])

	useEffect(() => {
		if (!permissions.viewScanAdherenceAnalytics) return
		getScanAdherenceNationwideScanMissed()
	}, [nationwideScanMissedInterval, page])

	const getNationwideDateRange = (interval, page) => {
		switch (interval) {
			case intervalTypes.MONTHLY: {
				const fromLimit = (209 * page) + (page - 1)
				const toLimit = 209 * (page - 1) + (page - 1)
				return {
					fromDate: moment().subtract(fromLimit, 'days').startOf('day').toDate(),
					toDate: moment().subtract(toLimit, 'days').endOf('day').toDate()
				}
			}
			case intervalTypes.WEEKLY: {
				const fromLimit = (48 * page) + (page - 1)
				const toLimit = 48 * (page - 1) + (page - 1)
				return {
					fromDate: moment().subtract(fromLimit, 'days').startOf('day').toDate(),
					toDate: moment().subtract(toLimit, 'days').endOf('day').toDate()
				}
			}
			case intervalTypes.DAILY:
			default: {
				const fromLimit = (page * 6) + (page - 1)
				const toLimit = 6 * (page - 1) + (page - 1)
				return {
					fromDate: moment().subtract(fromLimit, 'days').startOf('day').toDate(),
					toDate: moment().subtract(toLimit, 'days').endOf('day').toDate()
				}
			}
		}
	}

	const getDateRange = (interval) => {
		switch (interval) {
			case intervalTypes.MONTHLY:
				return {
					fromDate: moment().subtract(29, 'days').startOf('day').toDate(),
					toDate: moment().endOf('day').toDate()
				}
			case intervalTypes.WEEKLY:
				return {
					fromDate: moment().subtract(6, 'days').startOf('day').toDate(),
					toDate: moment().endOf('day').toDate()
				}
			case intervalTypes.DAILY:
			default:
				return {
					fromDate: moment().startOf('day').toDate(),
					toDate: moment().endOf('day').toDate()
				}
		}
	}

	const getScanAdherenceSummary = async () => {
		try {
			const params = {
				interval: summaryInterval,
				locationIds: selectedWarehouses,
				...getDateRange(summaryInterval)
			}
			if (summaryInterval === intervalTypes.CUSTOM) {
				if (!summaryFromDate || !summaryToDate) return
				params.fromDate = moment(summaryFromDate).startOf('day').toDate()
				params.toDate = moment(summaryToDate).endOf('day').toDate()
			}
			setIsFetchingSummary(true)
			await dispatch(fetchScanAdherenceSummary(params))
		} catch (e) {
			notification.error({
				message: 'Unable To Fetch Scan Adherence Summary',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingSummary(false)
		}
	}

	const getScanAdherenceWarehouseWiseScanMissed = async () => {
		try {
			const params = {
				interval: warehouseWiseScanMissedInterval,
				...getDateRange(warehouseWiseScanMissedInterval)
			}
			if (warehouseWiseScanMissedInterval === intervalTypes.CUSTOM) {
				if (!warehouseWiseFromDate || !warehouseWiseToDate) return
				params.fromDate = moment(warehouseWiseFromDate).startOf('day').toDate()
				params.toDate = moment(warehouseWiseToDate).endOf('day').toDate()
			}
			setIsFetchingWarehouseWiseScanMissed(true)
			await dispatch(fetchScanAdherenceWarehouseWiseScanMissed(params))
		} catch (e) {
			notification.error({
				message: 'Unable To Fetch Warehouse Wise Scan Missed CBCs',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingWarehouseWiseScanMissed(false)
		}
	}

	const getScanAdherenceNationwideScanMissed = async () => {
		try {
			setIsFetchingNationwideScanMissed(true)
			await dispatch(fetchScanAdherenceNationwideScanMissed({
				interval: nationwideScanMissedInterval,
				...getNationwideDateRange(nationwideScanMissedInterval, page),
				page
			}))
		} catch (e) {
			notification.error({
				message: 'Unable To Fetch Nationwide Scan Missed CBCs',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingNationwideScanMissed(false)
		}
	}

	const getWarehouses = async () => {
		try {
			setIsFetchingWarehouses(true)
			const response = await Locations.index({ page: -1, types: [locationTypes.WAREHOUSE, locationTypes.FACTORY] })
			if (response.data && response.data.results) {
				setWarehouses(response.data.results.map((item) => ({ value: item.id, label: item.label || item.address })))
				setSelectedWarehouses(response.data.results.map(item => item.id))
			}
		} catch (e) {
			notification.error({
				message: 'Unable To Fetch Warehouse',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingWarehouses(false)
		}
	}

	const formatNationwideScanMissedData = () => {
		switch (nationwideScanMissedInterval) {
			case 'daily':
				return nationwideScanMissed.map((item) => ({ ...item, date: moment(item.date).format('Do MMM yyyy') }))
			case 'monthly':
				return nationwideScanMissed.map((item) => ({ ...item, date: moment(item.date).format('MMMM yyyy') }))
			case 'weekly':
				return nationwideScanMissed.map((item) => ({
					...item,
					date: `${item.dateStr}: ${moment(item.dateStart).format('Do MMM')} - ${moment(item.dateEnd).format('Do MMM')}`
				}))
			default:
				return nationwideScanMissed
		}
	}

	const onWarehouseCheck = (value) => {
		if (selectedWarehouses.includes(value)) {
			setSelectedWarehouses(selectedWarehouses.filter(item => item !== value))
		} else {
			setSelectedWarehouses([...selectedWarehouses, value])
		}
	}

	const onSelectAllWarehouses = (e) => {
		if (e.target.checked) {
			setIsAllWarehousesChecked(true)
			const warehouseOptions = warehouseSearchValue ?
				warehouses.filter(item => item.label?.toLowerCase()?.includes(warehouseSearchValue.toLowerCase()))
				: warehouses
			setSelectedWarehouses(warehouseOptions.map(item => item.value))
		} else {
			setIsAllWarehousesChecked(false)
			setSelectedWarehouses([])
		}
	}

	const renderWarehouseOptions = () => {
		const warehouseOptions = warehouseSearchValue ?
			warehouses.filter(item => item.label?.toLowerCase()?.includes(warehouseSearchValue.toLowerCase()))
			: warehouses
		return (
			<div className={styles.optionsContainer}>
				<Checkbox
					onChange={onSelectAllWarehouses}
					checked={isAllWarehousesChecked}
					value={isAllWarehousesChecked}
				>
					Select All
				</Checkbox>
				<Divider style={{ margin: '5px 0px' }} />
				<div className={styles.options}>
					{
						warehouseOptions.map((option) => {
							return (
								<div style={{ marginTop: 5 }} key={option.value}>
									<Checkbox
										onChange={() => onWarehouseCheck(option.value)}
										checked={selectedWarehouses.includes(option.value)}
										value={option.value}
									>
										{option.label}
									</Checkbox>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}

	return (
		<div className={styles.page}>
			<div className={styles.section}>
				<div className={styles.header}>
					<h2 className={styles.title}>Overview</h2>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						shape='circle'
						onClick={getScanAdherenceSummary}
						loading={isFetchingSummary}
					/>
				</div>
				<div className={styles.cards}>
					<div className={styles.filters}>
						<div className={styles.selectContainer}>
							<Select
								loading={isFetchingWarehouses}
								style={{ width: '100%' }}
								showSearch
								onSearch={setWarehouseSearchValue}
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
								placeholder='Select Warehouses'
								options={warehouses}
								dropdownRender={renderWarehouseOptions}
							/>
						</div>
						<div>
							<div className={styles.selectContainer}>
								<Select
									style={{ width: '100%' }}
									placeholder='Select Interval'
									value={summaryInterval}
									onChange={setSummaryInterval}
									options={intervals}
								/>
							</div>
							{
								summaryInterval === intervalTypes.CUSTOM &&
								<Space
									size='small'
									style={{ marginTop: '16px' }}
								>
									<DatePicker
										placeholder='From'
										value={summaryFromDate ? moment(summaryFromDate) : null }
										onChange={(date) => setSummaryFromDate(date?.toDate())}
									/>
									<div className={styles.dash} />
									<DatePicker
										placeholder='To'
										value={summaryToDate ? moment(summaryToDate) : null }
										onChange={(date) => setSummaryToDate(date?.toDate())}
										disabledDate={current => current && current.valueOf() < moment(summaryFromDate)}
									/>
								</Space>
							}
						</div>
					</div>
					<Row gutter={16} justify='center'>
						<Col span={4}>
							<Card
								title={(
									<div className={styles.cardTitle}>
										<div>Total STO: {scanAdherenceSummary.totalSTOs}</div>
										<div>Total SO: {scanAdherenceSummary.totalSOs}</div>
									</div>
								)}
							>
								<div className={styles.cardValue}>Total SO & STO: {scanAdherenceSummary.totalOrders}</div>
							</Card>
						</Col>
						<Col span={4}>
							<Card
								title={(
									<div className={styles.cardTitle}>
										<div>% of Dispatch</div>
										<div>Orders Missed</div>
									</div>
								)}
							>
								<div className={styles.cardValue}>{showInPercentage(getPercentage(scanAdherenceSummary.deliveredOrdersMissed, scanAdherenceSummary.totalOrders))}</div>
							</Card>
						</Col>
						<Col span={4}>
							<Card
								title={(
									<div className={styles.cardTitle}>
										<div>% of Received</div>
										<div>Orders Missed</div>
									</div>
								)}
							>
								<div className={styles.cardValue}>{showInPercentage(getPercentage(scanAdherenceSummary.receivedOrdersMissed, scanAdherenceSummary.totalOrders))}</div>
							</Card>
						</Col>
						<Col span={4}>
							<Card
								title={(
									<div className={styles.cardTitle}>
										<div>Total Number of</div>
										<div>Scan Orders Missed</div>
									</div>
								)}
							>
								<div className={styles.cardValue}>{scanAdherenceSummary.deliveredOrdersMissed + scanAdherenceSummary.receivedOrdersMissed}</div>
							</Card>
						</Col>
						<Col span={4}>
							<Card
								title={(
									<div className={styles.cardTitle}>
										<div>Total Number of</div>
										<div>Scan Missed CBCs</div>
									</div>
								)}
							>
								<div className={styles.cardValue}>{scanAdherenceSummary.totalItemsMissed}</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles.spaceBetween}>
					<div className={styles.header}>
						<h2 className={styles.title}>% of CBC Scan Quantity Missed</h2>
						<Button
							style={{ marginLeft: 12, border: 'none' }}
							icon={<ReloadOutlined />}
							shape='circle'
							onClick={getScanAdherenceWarehouseWiseScanMissed}
							loading={isFetchingWarehouseWiseScanMissed}
						/>
					</div>
					<div>
						<div className={styles.selectContainer}>
							<Select
								style={{ width: '100%' }}
								placeholder='Select Interval'
								value={warehouseWiseScanMissedInterval}
								onChange={setWarehouseWiseScanMissedInterval}
								options={intervals}
							/>
						</div>
						{
							warehouseWiseScanMissedInterval === intervalTypes.CUSTOM &&
								<Space
									size='small'
									style={{ marginTop: '16px' }}
								>
									<DatePicker
										placeholder='From'
										value={warehouseWiseFromDate ? moment(warehouseWiseFromDate) : null }
										onChange={(date) => setWarehouseWiseFromDate(date?.toDate())}
									/>
									<div className={styles.dash} />
									<DatePicker
										placeholder='To'
										value={warehouseWiseToDate ? moment(warehouseWiseToDate) : null }
										onChange={(date) => setWarehouseWiseToDate(date?.toDate())}
										disabledDate={current => current && current.valueOf() < moment(warehouseWiseFromDate)}
									/>
								</Space>
						}
					</div>
				</div>
				<div className={styles.pieChart}>
					<ResponsivePie
						data={warehouseWiseScanMissed}
						margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
						colors={{ scheme: 'category10' }}
						id='label'
						value='totalItemsMissed'
						valueFormat={(value) => showInPercentage(getPercentage(value, totalScanMissedInAllWarehouses))}
						innerRadius={0}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						enableArcLinkLabels={true}
						arcLinkLabel={(data) => data.label}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'right',
								direction: 'column',
								justify: false,
								translateX: 0,
								translateY: 0,
								itemsSpacing: 1,
								itemWidth: 120,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 12,
								symbolShape: 'circle'
							}
						]}
					/>
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles.spaceBetween}>
					<div className={styles.header}>
						<h2 className={styles.title}>Nationwide CBC Scan Misses</h2>
						<Button
							style={{ marginLeft: 12, border: 'none' }}
							icon={<ReloadOutlined />}
							shape='circle'
							onClick={getScanAdherenceNationwideScanMissed}
							loading={isFetchingNationwideScanMissed}
						/>
					</div>
					<div className={styles.selectContainer}>
						<Select
							style={{ width: '100%' }}
							placeholder='Select Interval'
							value={nationwideScanMissedInterval}
							onChange={(value) => {
								setNationwideScanMissedInterval(value)
								setPage(1)
							}}
							options={nationwideIntervals}
						/>
					</div>
				</div>
				<div className={styles.barChartContainer}>
					<Button
						shape='circle'
						type={'ghost'}
						disabled={isFetchingNationwideScanMissed}
						icon={<LeftOutlined />}
						onClick={() => setPage(p => p + 1)}
					/>
					<div className={styles.barChart}>
						<ResponsiveBar
							data={formatNationwideScanMissedData()}
							keys={[
								'Received Quantity Missed (CBCs)',
								'Order Quantity Missed (CBCs)'
							]}
							indexBy='date'
							margin={{ top: 50, right: 50, bottom: 80, left: 60 }}
							padding={0.3}
							innerPadding={0}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							colors={{ scheme: 'category10' }}
							axisTop={null}
							axisRight={null}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor='white'
							legends={[
								{
									dataFrom: 'keys',
									anchor: 'bottom',
									direction: 'row',
									justify: false,
									translateX: 0,
									translateY: 65,
									itemsSpacing: 5,
									itemWidth: 200,
									itemHeight: 18,
									itemDirection: 'left-to-right',
									itemOpacity: 1,
									symbolSize: 12
								}
							]}
						/>
					</div>
					<Button
						shape='circle'
						type={'ghost'}
						icon={<RightOutlined />}
						disabled={page <= 1 || isFetchingNationwideScanMissed}
						onClick={() => setPage(p => p - 1)}
					/>
				</div>
			</div>
		</div>
	)
}

export default ScanAdherenceAnalytics

